









































































section {
  .tools-content {
    padding: 10px 0;
    border-bottom: 1px #ccc solid;
    .tools-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
      background-color: #fff;

      .input-wrapper {
        position: relative;

        > input {
          width: 400px;
          height: 35px;
          background-color: transparent;
          border: 1px #ccc solid;
          border-radius: 17px;
          box-sizing: border-box;
          outline: none;
          padding-left: 20px;
        }

        > i {
          width: 23px;
          height: 23px;
          position: absolute;
          top: 6px;
          right: 12px;
          display: inline-flex;
          background: url("../assets/img/search-btn.png") no-repeat center;
        }
      }

      .router-wrapper {
        display: flex;
        align-items: center;

        > * {
          margin-right: 5px;
          color: #bbbcbc;
          font-size: 16px;
        }

        > i {
          width: 23px;
          height: 23px;
          display: inline-flex;
          background: url("../assets/img/home.png") no-repeat center;
        }

        > a {
          transition: color 0.2s linear;
          &:hover {
            color: #333;
          }
        }
      }
    }
  }
  main {
    > h2 {
      text-align: center;
      font-size: 28px;
      color: #000;
      padding: 50px 0;
      line-height: 32px;
      font-weight: normal;
      border-bottom: 1px solid #f2f2f2;
    }

    > div {
      margin: 0 auto;
      width: 1200px;
      padding-top: 20px;
    }
  }
}
@media (max-width: 767px) {
  section {
    .tools-content {
      .tools-wrapper {
        width: 100%;
      }
    }
    main {
      padding: 0 15px;
      >div {
        width: 100%;
        ::v-deep img {
          max-width: 100%;
          height: auto;
        }
        ::v-deep >p {
          text-indent: 0 !important;
        }
      }
    }
  }
}
